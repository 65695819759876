<template>

  <div id="knowledge-base-page">
    <!-- JUMBOTRON -->
    <div class="knowledge-base-jumbotron">
      <div :style="{'background-image': 'url(https://cdn.discordapp.com/attachments/746242308084858900/859503094102753280/img21-scaled.png)'}" style="background-position: center!important;"
           class="knowledge-base-jumbotron-content lg:p-24 md:p-16 sm:p-10 p-6 rounded-lg bg-cover mb-base">
        <h1 class="mb-1 text-white" style="font-size: 2.9rem">Select a Course on Rapid Seminars</h1>
        <h5 class="text-white font-light">You may purchase access to a course by visiting Rapid Portal <a href="https://portal.rapid.education">Here</a></h5>
        <!---  <vs-input icon-no-border placeholder="Search for Course" v-model="course_selector_query" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6 transparent" /> //!TODO FIX SEARCH --->
      </div>
    </div>
    <!-- KNOWLEDGE BASE CARDS  -->
    <div>
      <slide-x-left-transition group class="vx-row match-height" tag="div">
        <div v-for="item in subscribedCourses" :key="item.id" class="vx-col card-width-select-course mb-base">
          <course-selector-card :item="item"/>
        </div>
      </slide-x-left-transition>
    </div>
  </div>

</template>

<style lang="scss">

.thumbnail {

  width: 15rem;

}

.course-container {
  cursor: pointer;
}


</style>

<script>
import CourseSelectorCard from "./components/CourseSelectorCard";

export default {
  components: {
    CourseSelectorCard
  },
  data() {
    return {
      subscribedCourses: [],
      selectedCourse: {},
    };
  },
  methods: {
    selectCourse(courseId) {
      return this.viewCourse(courseId);
      this.selectedCourse = this.subscribedCourses.filter(course => course.id === courseId)[0];
    },
    continueCourse(courseId) {
      this.$router.push(`/courses/${courseId}?opt=continue`);
    },
    viewCourse(courseId) {
      this.$router.push(`/courses/${courseId}`);
    },
  },
  mounted() {

    this.$http.get(`billing/@me/subscriptions`)
      .then(response => {

        if (response.data) {

          const payload = response.data.data;

          let courseIds = [];

          payload.forEach(course => {
            course.course_ids.forEach(id => {
              if (!courseIds.includes(id)) {
                courseIds.push(id);
              }
            });
          });

          if (courseIds.length === 0) {
            this.$vs.dialog({
              type: 'confirm',
              color: 'danger',

              title: 'No Subscribed Courses',
              text: `You don't have a subscription for any courses. Would you like to see a list of available ones?`,
              accept: () => {
                location.href = 'https://www.rapidseminars.com/courses.html';
              },
              cancel: () => this.$router.push('/'),
              close: () => this.$router.push('/'),
              acceptText: 'Sure!',
            });

          }

          courseIds.forEach(courseId => {

            this.$http.get(`courses/${courseId}`)
              .then(response => {

                if (response.data) {
                  const payload = response.data.data;

                  if (payload.status === 'active') {
                    this.subscribedCourses.push(payload);
                  }

                }

              });

          });

        }

      });

  },
};
</script>
<style lang="scss">


.card-width-select-course {
  width: 20%;
}

@media (max-width: 2900px) {
  .card-width-select-course {
    width: 25%;
  }
}

@media (max-width: 2100px) {
  .card-width-select-course {
    width: 33.333333%;
  }
}

@media (max-width: 1600px) {
  .card-width-select-course {
    width: 50%;
  }
}
@media (max-width: 750px) {
  .card-width-select-course {
    width: 100%;
  }
}


</style>
